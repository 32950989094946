<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item>
              <template slot="label">
                <div>
                  门店
                  <el-tooltip
                    effect="light"
                    content="门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.operateCompanyId"
                :operateCompanyUrl="form.operateCompanyUrl"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="车牌">
              <el-input
                v-model="form.carNumber"
                placeholder="请输入车牌"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="车单号">
              <el-input
                v-model="form.billCode"
                placeholder="请输入车单号"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                v-auth="676"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp" v-show="showMore">
          <template>
            <el-form-item label="资金流水号">
              <el-input
                v-model="form.transactionSeq"
                placeholder="请输入资金流水号"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="银行账号">
              <el-input
                v-model="form.bankAccount"
                placeholder="请输入银行账号"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="回款主体" prop="invoicingEntity">
              <el-select
                v-model="form.invoicingEntity"
                clearable
                filterable
                placeholder="请选择回款主体"
              >
                <el-option
                  v-for="item in invoicingEntities"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="付款主体" prop="insuranceCompanyId">
              <el-select
                v-model="form.insuranceCompanyId"
                clearable
                filterable
                placeholder="请选择付款主体"
              >
                <el-option
                  v-for="item in insuranceCompanyList"
                  :key="item.id"
                  :label="item.insuranceName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="认领时间" prop="claimDateTimes">
              <el-date-picker
                class="w100"
                v-model="form.claimDateTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="认领人" prop="claimOperator">
              <el-select
                v-model="form.claimOperator"
                filterable
                clearable
                remote
                placeholder="搜索姓名、手机号"
                :remote-method="searchAllUser"
                :loading="searchAllUserLoading"
                @select="doVerify('claimOperator')"
              >
                <el-option
                  v-for="item in allUserList"
                  :key="item.userId"
                  :label="item.realName"
                  :value="item.realName"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="认领备注">
              <el-input
                v-model="form.claimRemark"
                placeholder="请输入认领备注"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="银行摘要">
              <el-input
                v-model="form.bankSummary"
                placeholder="请输入银行摘要"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="回款时间">
              <el-date-picker
                class="w100"
                v-model="form.receivingDateTimes"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
                @input="dateRangeChange()"
              >
              </el-date-picker>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div></div>
        <div>
          <el-button
            v-auth="677"
            size="small"
            type="text"
            class="blue"
            @click="showExportDialog = true"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>

      <el-table
        class="custom-table summary"
        :data="listData"
        :span-method="objectSpanMethod"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column label="序号" width="60" prop="sequence">
        </el-table-column>
        <el-table-column width="200" label="回款时间" prop="receivingDatetime">
        </el-table-column>
        <el-table-column
          width="260"
          label="系统流水编号"
          prop="sysStatementSeqCode"
        >
        </el-table-column>
        <el-table-column
          width="260"
          label="付款主体"
          prop="insuranceCompanyName"
        >
        </el-table-column>
        <el-table-column width="200" label="付款账号" prop="paymentAccount">
        </el-table-column>
        <el-table-column width="200" label="回款主体" prop="invoicingEntity">
        </el-table-column>
        <el-table-column width="200" label="开户行" prop="depositBank">
        </el-table-column>
        <el-table-column width="200" label="银行账号" prop="bankAccount">
        </el-table-column>
        <el-table-column width="100" label="流水资金" prop="amount">
        </el-table-column>
        <el-table-column
          width="260"
          label="资金流水号"
          prop="transactionSeq"
        >
        </el-table-column>
        <el-table-column
          width="260"
          label="银行摘要"
          prop="bankSummary"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column width="260" label="车单门店" prop="billCompanyName">
        </el-table-column>
        <el-table-column width="200" label="车单号" prop="billCode">
          <template #default="{ row }">
            <div class="blue pointer" @click="goBillDetail(row.billId)">
              {{ row.billCode }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" label="车牌" prop="carNumber">
        </el-table-column>
        <el-table-column width="100" label="业务来源" prop="billSourceName">
        </el-table-column>
        <el-table-column width="120" label="本次认领金额" prop="claimAmount">
        </el-table-column>
        <el-table-column width="160" label="认领时间" prop="claimDateTime">
        </el-table-column>
        <el-table-column width="100" label="认领人" prop="claimUserName">
        </el-table-column>
        <el-table-column
          width="140"
          label="认领备注"
          prop="claimRemark"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column width="100" label="可认领金额" prop="unClaimAmount">
        </el-table-column>
        <el-table-column width="100" label="退款金额" prop="refundAmount">
        </el-table-column>
        <el-table-column width="100" label="业务冲抵费" prop="offsetFee">
        </el-table-column>
      </el-table>

      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
        :maxExportNum="30000"
      ></export-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
import ExportDialog from '@/components/ExportDialog';
// 接口
import { downloadFile } from '@/utils/fileApi';
import {
  getPreLoadSaveOrUpdateDropListData,
  transactionClaimDetailList,
  transactionClaimDetailListExportUrl,
} from '@/api/financial/financialOver';
import { getAllUser } from '@/api/employee';
import { hasAuth } from '@/utils/permissions';
import { postGetAllCompanyList } from '@/api/stock/inventoryWarning/index';

import { mapState } from 'vuex';

export default {
  name: 'transaction_claim_detail',
  components: {
    pagination,
    operateCompanyCascader,
    ExportDialog,
  },
  data() {
    return {
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      startPage: 1,
      endPage: 1,
      showExportDialog: false,
      exportUrl: transactionClaimDetailListExportUrl,

      form: {
        operateCompanyId: [],
        billCode: '',
        carNumber: '',
        receivingStatus: '',
        checkoutStatus: '',
        writeOffStatus: '',
      },
      receivingStatusEnum: [],
      checkoutStatusEnum: [],
      writeOffStatusEnum: [],
      // 展示更多筛选
      showMore: true,
      listData: [],
      showDetailDialog: false,
      showEditDialog: false,
      billId: '',
      editId: '',
      rowEntityData: {},
      showChangeWriteOffRemarkDialog: false,
      showWriteOffStatusDialog: false,
      writeOffId: undefined,
      writeOffRemark: '',
      showReversalWriteOffStatusDialog: false,
      reversalWriteOffId: undefined,
      reversalWriteOffRemark: '',
      // 预留给认领的数据
      companyList: [],
      insuranceCompanyList: [],
      invoicingEntities: [],
      searchAllUserLoading: false,
      searchUserTimer: undefined,
      allUserList: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.empty();
      this.initData();
      this.getData();
    },
    initData() {
      // 如果是跳转过来的，拿到门店、车单号、车牌号，直接定位到车单中，以便进行认领——如定损单页面、接车单页面
      const queryCompanyId = this.$route.query.companyId;
      if (queryCompanyId) {
        this.form.operateCompanyId = [queryCompanyId];
      } else {
        this.form.operateCompanyId = [this.companyId];
      }
      const queryCarNumber = this.$route.query.carNumber;
      if (queryCarNumber) {
        this.form.carNumber = queryCarNumber;
      }
      const queryBillCode = this.$route.query.billCode;
      if (queryBillCode) {
        this.form.billCode = queryBillCode;
      }
      this.getInsuranceCompanyListAndInvoicingEntities();
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    /**
     * 查询体系内的门店列表
     */
    async getInsuranceCompanyListAndInvoicingEntities() {
      const _this = this;
      getPreLoadSaveOrUpdateDropListData().then((res) => {
        const { insuranceCompanyList, invoicingEntities } = res;
        _this.insuranceCompanyList = insuranceCompanyList;
        _this.invoicingEntities = invoicingEntities;
      });
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(676);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {
        claimBeginDateTime: this.form.claimDateTimes?.[0]
          ? this.form.claimDateTimes?.[0] + ' 00:00:00'
          : '',
        claimEndDateTime: this.form.claimDateTimes?.[1]
          ? this.form.claimDateTimes?.[1] + ' 23:59:59'
          : '',
        receivingBeginDateTime: this.form.receivingDateTimes?.[0]
          ? this.form.receivingDateTimes?.[0] + ' 00:00:00'
          : '',
        receivingEndDateTime: this.form.receivingDateTimes?.[1]
          ? this.form.receivingDateTimes?.[1] + ' 23:59:59'
          : '',
      };
      var data = Object.assign(this.form, this.pageOption, times);
      delete data.exportStartNum;
      delete data.exportEndNum;
      const _this = this;
      transactionClaimDetailList(data).then((res) => {
        // 修改总条数
        _this.total = res.total;
        // 赋值records
        _this.listData = res.records;
        _this.rowspan(this.listData);
      });
    },
    //表格中对数据进行处理
    rowspan(arr) {
      let billMap = {};
      arr.forEach((item) => {
        if (billMap[item.id]) {
          billMap[item.id]++;
        } else {
          billMap[item.id] = 1;
        }
        item.sequence = Object.keys(billMap).length;
      });
      console.log(billMap);

      arr.forEach((item, index) => {
        if (index === 0 || item.id != arr[index - 1].id) {
          item.billSpan = billMap[item.id];
        }
      });

      let table = arr.map((item) => ({
        billId: item.id,
        billSpan: item.billSpan,
      }));
      console.table(table);
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(columnIndex)) {
        return {
          rowspan: row.billSpan || 0, //行
          colspan: row.billSpan ? 1 : 0, //列
        };
      }
    },
    /**
     * 搜索跟进人列表
     */
    searchAllUser(msg) {
      if (this.searchUserTimer) {
        clearTimeout(this.searchUserTimer);
      }
      this.searchGoodTimer = setTimeout(() => {
        this.searchAllUserLoading = true;
        let params = {};
        if (typeof msg === 'object') {
          params = msg;
        } else {
          params = { msg };
        }
        getAllUser(params)
          .then((res) => {
            this.allUserList = res.records || [];
          })
          .finally(() => {
            this.searchAllUserLoading = false;
          });
      }, 800);
    },
    /**
     * 跳转到车单详情页面
     * @param billId 车单ID
     */
    goBillDetail(billId) {
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id: billId,
          t: new Date().getTime(),
        },
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {
        operateCompanyId: [this.companyId],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
.dialogSecTitle {
  padding: 10px 5px;
}
</style>
